import { useCallback, useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";
import { useToast } from "@/components/ui/use-toast";

interface DropZoneProps {
  onFilesSelected: (files: File[]) => Promise<void>;
  disabled?: boolean;
  multiple?: boolean;
  itemId?: string;
}

export function DropZone({ onFilesSelected, disabled, multiple, itemId }: DropZoneProps) {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback(async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (!itemId || disabled) return;

    const files = Array.from(e.dataTransfer.files);
    if (files.length > 1 && !multiple) {
      toast({
        title: t('forms.errors.singlePhotoOnly'),
        description: t('forms.errors.tryAgain'),
        variant: "destructive",
      });
      return;
    }

    await onFilesSelected(files);
  }, [itemId, multiple, disabled, onFilesSelected]);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !itemId || disabled) return;

    const files = Array.from(e.target.files);
    if (files.length > 1 && !multiple) {
      toast({
        title: t('forms.errors.singlePhotoOnly'),
        description: t('forms.errors.tryAgain'),
        variant: "destructive",
      });
      return;
    }

    await onFilesSelected(files);
    e.target.value = '';
  };

  return (
    <div
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className={`
        relative border-2 border-dashed rounded-lg p-8 text-center
        transition-colors duration-200 ease-in-out
        ${dragActive ? 'border-primary bg-secondary/50' : 'border-border'}
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:border-primary hover:bg-secondary/20'}
      `}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        disabled={disabled}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer disabled:cursor-not-allowed"
      />
      <div className="space-y-2">
        <p className="text-sm text-muted-foreground">
          {disabled ? (
            t('forms.status.createFirst')
          ) : (
            t('forms.placeholders.uploadPhoto')
          )}
        </p>
      </div>
    </div>
  );
}