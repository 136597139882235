export default {
  common: {
    error: "Erreur",
    success: "Succès",
    cancel: "Annuler",
    save: "Enregistrer",
    delete: "Supprimer",
    edit: "Modifier",
    create: "Créer",
    loading: "Chargement...",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer cet élément ?",
    required: "Ce champ est obligatoire",
    optional: "Optionnel",
    back: "Retour",
    actions: "Actions",
    view: "Voir",
    status: "Statut",
    active: "Actif",
    inactive: "Inactif",
    submit: "Soumettre",
    search: "Rechercher",
    noResults: "Aucun résultat trouvé",
    close: "Fermer",
    next: "Suivant",
    finish: "Terminer"
  },
  forms: {
    labels: {
      title: "Titre",
      description: "Description",
      additions: "Suppléments",
      photo: "Photo"
    },
    placeholders: {
      title: "Entrez le titre",
      description: "Entrez la description",
      uploadPhoto: "Cliquez pour télécharger ou glissez-déposez",
      searchStockImages: "Rechercher des images...",
      aiPrompt: "Entrez une description de l'image que vous souhaitez générer..."
    },
    tooltips: {
      title: "Le nom de cet élément qui sera affiché aux clients",
      description: "Détails supplémentaires sur cet élément",
      additions: "Extras optionnels qui peuvent être ajoutés aux éléments de cette catégorie",
      singlePhotoUpload: "Téléchargez une seule photo pour cet élément"
    },
    buttons: {
      stockImages: "Choisir une image",
      generateAI: "Générer avec l'IA",
      generating: "Génération en cours...",
      generate: "Générer"
    },
    titles: {
      stockImages: "Images stock",
      generateImage: "Générer une image avec l'IA"
    },
    status: {
      createFirst: "Créez d'abord l'élément avant de télécharger une photo"
    },
    errors: {
      imageOnly: "Seuls les fichiers image sont autorisés",
      singlePhotoOnly: "Une seule photo peut être téléchargée",
      uploadFailed: "Échec du téléchargement de la photo",
      stockImageFailed: "Échec de la sélection de l'image stock",
      aiGenerationFailed: "Échec de la génération de l'image",
      tryAgain: "Veuillez réessayer"
    },
    success: {
      photoUploaded: "Photo téléchargée",
      photoUploadedDesc: "Votre photo a été téléchargée avec succès"
    }
  },
  dashboard: {
    title: "Tableau de bord",
    welcome: "Bon retour",
    description: "Visualisez et gérez les performances de votre restaurant"
  },
  categories: {
    title: "Catégories",
    description: "Gérez vos catégories de menu",
    create: "Créer une catégorie",
    edit: "Modifier la catégorie",
    delete: "Supprimer la catégorie",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer cette catégorie ?",
    deleted: "Catégorie supprimée",
    deleteSuccess: "La catégorie a été supprimée avec succès",
    deleteError: "Une erreur s'est produite lors de la suppression de la catégorie",
    loadError: "Erreur lors du chargement des catégories",
    saved: "Catégorie enregistrée",
    saveSuccess: "La catégorie a été enregistrée avec succès",
    saveError: "Une erreur s'est produite lors de l'enregistrement de la catégorie",
    additions: "Suppléments"
  },
  foodItems: {
    title: "Plats",
    description: "Gérez vos articles de menu",
    create: "Créer un plat",
    edit: "Modifier le plat",
    delete: "Supprimer le plat",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer ce plat ?",
    deleted: "Plat supprimé",
    deleteSuccess: "Le plat a été supprimé avec succès",
    deleteError: "Une erreur s'est produite lors de la suppression du plat",
    loadError: "Erreur lors du chargement des plats",
    saved: "Plat enregistré",
    saveSuccess: "Le plat a été enregistré avec succès",
    saveError: "Une erreur s'est produite lors de l'enregistrement du plat"
  },
  menus: {
    title: "Menus",
    description: "Gérez les menus de votre restaurant",
    create: "Créer un menu",
    edit: "Modifier le menu",
    delete: "Supprimer le menu",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer ce menu ?",
    deleted: "Menu supprimé",
    deleteSuccess: "Le menu a été supprimé avec succès",
    deleteError: "Une erreur s'est produite lors de la suppression du menu",
    loadError: "Erreur lors du chargement des menus",
    saved: "Menu enregistré",
    saveSuccess: "Le menu a été enregistré avec succès",
    saveError: "Une erreur s'est produite lors de l'enregistrement du menu"
  },
  promotions: {
    title: "Promotions",
    description: "Gérez vos offres spéciales et promotions",
    create: "Créer une promotion",
    edit: "Modifier la promotion",
    delete: "Supprimer la promotion",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer cette promotion ?",
    deleted: "Promotion supprimée",
    deleteSuccess: "La promotion a été supprimée avec succès",
    deleteError: "Une erreur s'est produite lors de la suppression de la promotion",
    loadError: "Erreur lors du chargement des promotions",
    saved: "Promotion enregistrée",
    saveSuccess: "La promotion a été enregistrée avec succès",
    saveError: "Une erreur s'est produite lors de l'enregistrement de la promotion"
  },
  auth: {
    signIn: "Se connecter",
    signOut: "Se déconnecter",
    email: "Email",
    password: "Mot de passe",
    forgotPassword: "Mot de passe oublié ?",
    resetPassword: "Réinitialiser le mot de passe",
    loginError: "Erreur de connexion",
    invalidCredentials: "Email ou mot de passe incorrect"
  }
};
