export default {
  common: {
    error: "Errore",
    success: "Successo",
    cancel: "Annulla",
    save: "Salva",
    delete: "Elimina",
    edit: "Modifica",
    create: "Crea",
    loading: "Caricamento...",
    required: "Obbligatorio",
    optional: "Opzionale",
    back: "Indietro",
    next: "Avanti",
    finish: "Fine",
    view: "Visualizza",
    close: "Chiudi",
    search: "Cerca",
    noResults: "Nessun risultato trovato",
    actions: "Azioni",
    submit: "Invia",
    status: "Stato",
    active: "Attivo",
    inactive: "Inattivo"
  },
  forms: {
    labels: {
      title: "Titolo",
      description: "Descrizione",
      additions: "Aggiunte",
      photo: "Foto"
    },
    placeholders: {
      title: "Inserisci il titolo",
      description: "Inserisci la descrizione",
      uploadPhoto: "Clicca per caricare o trascina qui",
      searchStockImages: "Cerca immagini stock...",
      aiPrompt: "Inserisci una descrizione dell'immagine che vuoi generare..."
    },
    tooltips: {
      title: "Il nome di questo elemento che sarà mostrato ai clienti",
      description: "Dettagli aggiuntivi su questo elemento",
      additions: "Extra opzionali che possono essere aggiunti agli elementi di questa categoria",
      singlePhotoUpload: "Carica una singola foto per questo elemento"
    },
    buttons: {
      stockImages: "Scegli immagine stock",
      generateAI: "Genera con IA",
      generating: "Generazione in corso...",
      generate: "Genera"
    },
    titles: {
      stockImages: "Immagini stock",
      generateImage: "Genera immagine con IA"
    },
    status: {
      createFirst: "Crea prima l'elemento prima di caricare una foto"
    },
    errors: {
      imageOnly: "Sono ammessi solo file immagine",
      singlePhotoOnly: "È possibile caricare una sola foto",
      uploadFailed: "Caricamento foto fallito",
      stockImageFailed: "Selezione immagine stock fallita",
      aiGenerationFailed: "Generazione immagine fallita",
      tryAgain: "Per favore riprova"
    },
    success: {
      photoUploaded: "Foto caricata",
      photoUploadedDesc: "La tua foto è stata caricata con successo"
    }
  },
  dashboard: {
    title: "Dashboard",
    welcome: "Bentornato",
    description: "Visualizza e gestisci le prestazioni del tuo ristorante"
  },
  categories: {
    title: "Categorie",
    description: "Gestisci le categorie del menu",
    create: "Crea categoria",
    edit: "Modifica categoria",
    delete: "Elimina categoria",
    deleteConfirm: "Sei sicuro di voler eliminare questa categoria?",
    deleted: "Categoria eliminata",
    deleteSuccess: "La categoria è stata eliminata con successo",
    deleteError: "Si è verificato un errore durante l'eliminazione della categoria",
    loadError: "Impossibile caricare le categorie",
    createSuccess: "Categoria creata con successo",
    updateSuccess: "Categoria aggiornata con successo",
    additions: "Aggiunte"
  },
  foodItems: {
    title: "Piatti",
    description: "Gestisci gli elementi del menu"
  },
  menus: {
    title: "Menu",
    description: "Gestisci i menu del tuo ristorante"
  },
  promotions: {
    title: "Promozioni",
    description: "Gestisci le tue offerte speciali e promozioni"
  },
  auth: {
    signIn: "Accedi",
    signOut: "Esci",
    signInError: "Errore di accesso",
    unauthorized: "Non autorizzato",
    sessionExpired: "La tua sessione è scaduta. Per favore accedi di nuovo."
  }
};