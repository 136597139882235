export default {
  common: {
    error: "Error",
    success: "Success",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit",
    create: "Create",
    loading: "Loading...",
    required: "Required",
    optional: "Optional",
    back: "Back",
    next: "Next",
    finish: "Finish",
    view: "View",
    close: "Close",
    search: "Search",
    noResults: "No results found",
    actions: "Actions",
    submit: "Submit",
  },
  forms: {
    labels: {
      title: "Title",
      description: "Description",
      additions: "Additions",
      photo: "Photo",
    },
    placeholders: {
      title: "Enter title",
      description: "Enter description",
      uploadPhoto: "Click to upload or drag and drop",
      searchStockImages: "Search stock images...",
      aiPrompt: "Enter a description of the image you want to generate...",
    },
    tooltips: {
      title: "The name of this item that will be displayed to customers",
      description: "Additional details about this item",
      additions: "Optional extras that can be added to items in this category",
      singlePhotoUpload: "Upload a single photo for this item",
    },
    buttons: {
      stockImages: "Choose Stock Image",
      generateAI: "Generate with AI",
      generating: "Generating...",
      generate: "Generate",
    },
    titles: {
      stockImages: "Stock Images",
      generateImage: "Generate Image with AI",
    },
    status: {
      createFirst: "Create the item first before uploading a photo",
    },
    errors: {
      imageOnly: "Only image files are allowed",
      singlePhotoOnly: "Only one photo can be uploaded",
      uploadFailed: "Failed to upload photo",
      stockImageFailed: "Failed to select stock image",
      aiGenerationFailed: "Failed to generate image",
      tryAgain: "Please try again",
    },
    success: {
      photoUploaded: "Photo uploaded",
      photoUploadedDesc: "Your photo has been uploaded successfully",
    },
  },
  dashboard: {
    title: "Dashboard",
    welcome: "Welcome back",
    description: "View and manage your restaurant's performance"
  },
  categories: {
    title: "Categories",
    description: "Manage your menu categories",
    create: "Create Category",
    edit: "Edit Category",
    delete: "Delete Category",
    deleteConfirm: "Are you sure you want to delete this category?",
    deleted: "Category deleted",
    deleteSuccess: "Category has been successfully deleted",
    deleteError: "An error occurred while deleting the category",
    loadError: "Could not load categories",
    createSuccess: "Category successfully created",
    updateSuccess: "Category successfully updated",
    additions: "Additions",
    detailsInfo: "View and manage category details",
    additionsCount: "additions",
    editSuccess: "Category updated successfully",
    editError: "Failed to update category",
  },
  foodItems: {
    title: "Food Items",
    description: "Manage your menu items",
  },
  menus: {
    title: "Menus",
    description: "Manage your restaurant menus",
  },
  promotions: {
    title: "Promotions",
    description: "Manage your special offers and promotions",
  },
};
