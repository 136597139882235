export default {
  common: {
    error: "Fehler",
    success: "Erfolg",
    cancel: "Abbrechen",
    save: "Speichern",
    delete: "Löschen",
    edit: "Bearbeiten",
    create: "Erstellen",
    loading: "Laden...",
    required: "Erforderlich",
    optional: "Optional",
    back: "Zurück",
    next: "Weiter",
    finish: "Fertig",
    view: "Ansehen",
    close: "Schließen",
    search: "Suchen",
    noResults: "Keine Ergebnisse gefunden",
    actions: "Aktionen",
    submit: "Absenden",
    status: "Status",
    active: "Aktiv",
    inactive: "Inaktiv"
  },
  forms: {
    labels: {
      title: "Titel",
      description: "Beschreibung",
      additions: "Zusätze",
      photo: "Foto"
    },
    placeholders: {
      title: "Titel eingeben",
      description: "Beschreibung eingeben",
      uploadPhoto: "Zum Hochladen klicken oder Datei hierher ziehen",
      searchStockImages: "Stockfotos durchsuchen...",
      aiPrompt: "Beschreiben Sie das Bild, das Sie generieren möchten..."
    },
    tooltips: {
      title: "Der Name dieses Elements, der den Kunden angezeigt wird",
      description: "Zusätzliche Details zu diesem Element",
      additions: "Optionale Extras, die zu Elementen dieser Kategorie hinzugefügt werden können",
      singlePhotoUpload: "Laden Sie ein einzelnes Foto für dieses Element hoch"
    },
    buttons: {
      stockImages: "Stockfoto auswählen",
      generateAI: "Mit KI generieren",
      generating: "Generiere...",
      generate: "Generieren"
    },
    titles: {
      stockImages: "Stockfotos",
      generateImage: "Bild mit KI generieren"
    },
    status: {
      createFirst: "Erstellen Sie zuerst das Element, bevor Sie ein Foto hochladen"
    },
    errors: {
      imageOnly: "Nur Bilddateien sind erlaubt",
      singlePhotoOnly: "Es kann nur ein Foto hochgeladen werden",
      uploadFailed: "Foto konnte nicht hochgeladen werden",
      stockImageFailed: "Stockfoto konnte nicht ausgewählt werden",
      aiGenerationFailed: "Bild konnte nicht generiert werden",
      tryAgain: "Bitte versuchen Sie es erneut"
    },
    success: {
      photoUploaded: "Foto hochgeladen",
      photoUploadedDesc: "Ihr Foto wurde erfolgreich hochgeladen"
    }
  },
  dashboard: {
    title: "Dashboard",
    welcome: "Willkommen zurück",
    description: "Sehen und verwalten Sie die Leistung Ihres Restaurants"
  },
  categories: {
    title: "Kategorien",
    description: "Verwalten Sie Ihre Menükategorien",
    create: "Kategorie erstellen",
    edit: "Kategorie bearbeiten",
    delete: "Kategorie löschen",
    deleteConfirm: "Sind Sie sicher, dass Sie diese Kategorie löschen möchten?",
    deleted: "Kategorie gelöscht",
    deleteSuccess: "Die Kategorie wurde erfolgreich gelöscht",
    deleteError: "Beim Löschen der Kategorie ist ein Fehler aufgetreten",
    loadError: "Kategorien konnten nicht geladen werden",
    createSuccess: "Kategorie erfolgreich erstellt",
    updateSuccess: "Kategorie erfolgreich aktualisiert",
    additions: "Zusätze"
  },
  foodItems: {
    title: "Speisen",
    description: "Verwalten Sie Ihre Menüpunkte",
    create: "Speise erstellen",
    edit: "Speise bearbeiten",
    delete: "Speise löschen",
    deleteConfirm: "Sind Sie sicher, dass Sie diese Speise löschen möchten?",
    deleted: "Speise gelöscht",
    deleteSuccess: "Die Speise wurde erfolgreich gelöscht",
    deleteError: "Beim Löschen der Speise ist ein Fehler aufgetreten",
    loadError: "Speisen konnten nicht geladen werden",
    createSuccess: "Speise erfolgreich erstellt",
    updateSuccess: "Speise erfolgreich aktualisiert"
  },
  menus: {
    title: "Speisekarten",
    description: "Verwalten Sie Ihre Restaurantmenüs",
    create: "Speisekarte erstellen",
    edit: "Speisekarte bearbeiten",
    delete: "Speisekarte löschen",
    deleteConfirm: "Sind Sie sicher, dass Sie diese Speisekarte löschen möchten?",
    deleted: "Speisekarte gelöscht",
    deleteSuccess: "Die Speisekarte wurde erfolgreich gelöscht",
    deleteError: "Beim Löschen der Speisekarte ist ein Fehler aufgetreten",
    loadError: "Speisekarten konnten nicht geladen werden",
    createSuccess: "Speisekarte erfolgreich erstellt",
    updateSuccess: "Speisekarte erfolgreich aktualisiert"
  },
  promotions: {
    title: "Aktionen",
    description: "Verwalten Sie Ihre Sonderangebote und Aktionen",
    create: "Aktion erstellen",
    edit: "Aktion bearbeiten",
    delete: "Aktion löschen",
    deleteConfirm: "Sind Sie sicher, dass Sie diese Aktion löschen möchten?",
    deleted: "Aktion gelöscht",
    deleteSuccess: "Die Aktion wurde erfolgreich gelöscht",
    deleteError: "Beim Löschen der Aktion ist ein Fehler aufgetreten",
    loadError: "Aktionen konnten nicht geladen werden",
    createSuccess: "Aktion erfolgreich erstellt",
    updateSuccess: "Aktion erfolgreich aktualisiert"
  },
  auth: {
    signIn: "Anmelden",
    signOut: "Abmelden",
    signInError: "Anmeldung fehlgeschlagen",
    unauthorized: "Nicht autorisiert",
    sessionExpired: "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an."
  }
};
