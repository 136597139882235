import { useQuery } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { Button } from "@/components/ui/button";
import { Plus, Pencil, Trash2, Clock, Eye, DollarSign, Tag, Layers, Utensils } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/hooks/use-toast";
import { useTranslation } from "@/hooks/useTranslation";
import { useLocalizedText } from "@/utils/textual";
import { Card, CardContent } from "@/components/ui/card";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

export default function Index() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation();
  const getLocalizedText = useLocalizedText();

  const { data: foodItems = [], refetch } = useQuery({
    queryKey: ['foodItems'],
    queryFn: () => mockStaffClient.listFoodItems(),
  });

  const { data: categories = [] } = useQuery({
    queryKey: ['categories'],
    queryFn: () => mockStaffClient.listCategories(),
  });

  const handleDelete = async (id: string) => {
    try {
      await mockStaffClient.deleteFoodItem(id);
      toast({
        title: t('common.success'),
        description: t('foodItems.deleteSuccess'),
      });
      refetch();
    } catch (error) {
      toast({
        title: t('common.error'),
        description: t('foodItems.deleteError'),
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-bold">{t('foodItems.title')}</h1>
        </div>
        <Button onClick={() => navigate("/food-items/create")}>
          <Plus className="mr-2 h-4 w-4" />
          {t('foodItems.create')}
        </Button>
      </div>

      <div className="grid gap-4">
        {foodItems.map((item) => {
          const { title, description } = getLocalizedText(item.textualData);
          const price = (item.price.amount / 100).toFixed(2);
          const itemCategories = categories.filter(cat => item.categoryIds.includes(cat.id));
          
          return (
            <Card key={item.id}>
              <CardContent className="p-6">
                <div className="flex gap-4">
                  {item.photoUrl ? (
                    <img
                      src={item.photoUrl}
                      alt={title}
                      className="w-24 h-24 object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-24 h-24 bg-gray-100 rounded-lg flex items-center justify-center">
                      <Utensils className="w-8 h-8 text-gray-400" />
                    </div>
                  )}
                  <div className="flex-1">
                    <div className="flex justify-between">
                      <div className="space-y-4">
                        <div>
                          <h3 className="font-semibold text-lg">{title}</h3>
                          <p className="text-muted-foreground mt-1">{description}</p>
                        </div>
                        <div className="flex flex-wrap gap-2">
                          <Badge variant="secondary" className="flex items-center gap-1 bg-orange-100 text-orange-700">
                            <DollarSign className="h-3 w-3" />
                            €{price}
                          </Badge>
                          {itemCategories.length > 0 && (
                            <Badge variant="secondary" className="flex items-center gap-1 bg-blue-100 text-blue-700">
                              <Tag className="h-3 w-3" />
                              {itemCategories.length} {t('common.categories')}
                            </Badge>
                          )}
                          {item.variations.length > 0 && (
                            <Badge variant="secondary" className="flex items-center gap-1 bg-purple-100 text-purple-700">
                              <Layers className="h-3 w-3" />
                              {item.variations.length} {t('common.variations')}
                            </Badge>
                          )}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <Button
                          variant="outline"
                          size="icon"
                          onClick={() => navigate(`/food-items/${item.id}`)}
                        >
                          <Eye className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="outline"
                          size="icon"
                          onClick={() => navigate(`/food-items/${item.id}/edit`)}
                        >
                          <Pencil className="h-4 w-4" />
                        </Button>
                        <AlertDialog>
                          <AlertDialogTrigger asChild>
                            <Button variant="outline" size="icon" className="text-destructive">
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>{t('foodItems.delete')}</AlertDialogTitle>
                              <AlertDialogDescription>
                                {t('foodItems.deleteConfirm')}
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
                              <AlertDialogAction
                                onClick={() => handleDelete(item.id)}
                                className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                              >
                                {t('common.delete')}
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </div>
  );
}