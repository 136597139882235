export const mockStats = {
  foodItems: {
    today: [
      { id: "1", title: "Spaghetti Carbonara", orders: 42 },
      { id: "2", title: "Tiramisu", orders: 28 },
      { id: "3", title: "Margherita Pizza", orders: 35 },
      { id: "4", title: "Lasagna", orders: 31 },
      { id: "5", title: "Gelato", orders: 25 }
    ],
    yearly: [
      { id: "1", title: "Spaghetti Carbonara", orders: 15420 },
      { id: "2", title: "Tiramisu", orders: 10234 },
      { id: "3", title: "Margherita Pizza", orders: 12500 },
      { id: "4", title: "Lasagna", orders: 11800 },
      { id: "5", title: "Gelato", orders: 9500 }
    ]
  },
  categories: {
    today: [
      { id: "1", title: "Main Dishes", orders: 156 },
      { id: "2", title: "Desserts", orders: 89 },
      { id: "3", title: "Pizzas", orders: 120 },
      { id: "4", title: "Pasta", orders: 145 },
      { id: "5", title: "Beverages", orders: 78 }
    ],
    yearly: [
      { id: "1", title: "Main Dishes", orders: 52340 },
      { id: "2", title: "Desserts", orders: 34120 },
      { id: "3", title: "Pizzas", orders: 45600 },
      { id: "4", title: "Pasta", orders: 48900 },
      { id: "5", title: "Beverages", orders: 28500 }
    ]
  }
};