import { CategoryFormValues } from "@/types";
import { mockCategories } from "../mockData/categories";

let categories = [...mockCategories];

export const categoriesClient = {
  list: async () => {
    console.log('Listing categories:', categories);
    return Promise.resolve(categories);
  },

  create: async (category: CategoryFormValues) => {
    console.log('Creating category:', category);
    
    const newCategory = {
      ...category,
      id: Math.random().toString(36).substring(7),
      photoUrl: category.photoUrl || null,
    };
    
    categories.push(newCategory);
    console.log('Created category:', newCategory);
    return Promise.resolve(newCategory);
  },

  get: async (id: string) => {
    console.log('Getting category:', id);
    const category = categories.find(cat => cat.id === id);
    console.log('Found category:', category);
    return Promise.resolve(category);
  },

  update: async (id: string, category: CategoryFormValues) => {
    console.log('Updating category:', id, category);
    
    const index = categories.findIndex(cat => cat.id === id);
    if (index === -1) {
      throw new Error('Category not found');
    }
    
    const updatedCategory = {
      ...category,
      id,
      photoUrl: category.photoUrl || null,
    };
    
    categories[index] = updatedCategory;
    console.log('Updated category:', updatedCategory);
    return Promise.resolve(updatedCategory);
  },

  delete: async (id: string) => {
    console.log('Deleting category:', id);
    categories = categories.filter(cat => cat.id !== id);
    return Promise.resolve(true);
  }
};