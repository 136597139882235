import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CategoryForm } from "@/components/categories/CategoryForm";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { useTranslation } from "@/hooks/useTranslation";

export default function CreateCategory() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const isEditing = !!id;

  const { data: category, isLoading } = useQuery({
    queryKey: ["categories", id],
    queryFn: () => mockStaffClient.getCategory(id!),
    enabled: !!id,
  });

  console.log("CreateCategory - Mode:", isEditing ? "Edit" : "Create");
  console.log("CreateCategory - Loading:", isLoading);
  console.log("CreateCategory - Category data:", category);

  if (isEditing && isLoading) {
    return <div>{t("common.loading")}</div>;
  }

  return (
    <div className="max-w-2xl mx-auto">
      <Card>
        <CardHeader>
          <CardTitle>
            {isEditing ? t("categories.edit") : t("categories.create")}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <CategoryForm 
            initialData={category} 
            isEditing={isEditing} 
          />
        </CardContent>
      </Card>
    </div>
  );
}